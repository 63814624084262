/**
 * Endpoint of the application
 */
export const EndpointConstant = {

  APPLICATION_SUBMISSION: 'submitApp',
  ACTIVE_REWARD_TYPES: `getActiveRewardTypes`,
  ACADEMIC_COURSE_LIST_BY_CID: (cid: string) => `Course/GetCourseDetails/${cid}`,
  AUTO_REPLY_CREATE: 'createAutoReplyQuestions',
  AUTO_REPLY_DELETE: (id: string) => `deleteAutoReplyQuestions/${id}`,
  AUTO_REPLY_LIST: (label: string, value: string, pageLimit: number, page: number) =>
      `getAutoReplyQuestions?page=${page}&page_limit=${pageLimit}&${label}=${value}`,
  AUTO_REPLY_UPDATE: (id: string) => `updateAutoReplyQuestions/${id}`,
  
  BUSINESS_UNIT_TYPES: (businessUnit: string) => `get_master_table_data?table=${businessUnit}`,

  CERTIFICATION_TYPE_GET: (qualId: number) => `getCertTypeByQualId/${qualId}`,
  
  CHILD_MODULE_ASSESSMENTS: (childModuleId, childModuleItemId) => `childmodules/${childModuleId}/moduleItems/${childModuleItemId}/assesments`,
  COURSE_FILTER_BY_CERTIFICATION_AND_QUALIFICATION_ID: (certificateTypeId, qualificationTypeId) =>
    `courses?fk_certificate_type_id=${certificateTypeId}&fk_type_of_qualification_id=${qualificationTypeId}`,
  COURSE_BY_ID_GET: (courseId: number) => `courselist/${courseId}`,
  COUNTRY_LIST: `countries`,
  COURSE_LIST: `courselist?fk_type_of_qualification_id=1&status=1&page_limit=12`,
  COURSE_PAYMENT_TYPE: (externalCourseId: number) => `Course/GetCoursePaymentType/${externalCourseId}`,
  CMI_CERTIFICATE_DELETE: (paymentId: string) => `deleteCMICertificate/${paymentId}`,
  CMI_CERTIFICATE_DELETE_REMARK: (remarkId: string) => `deleteCertificateRemark/${remarkId}`,
  CMI_CERTIFICATE_LIST_GET: (pageNumber: number, pageLimit: number, searchType: string, searchField: string) =>
  `getCMICertificateList?page=${pageNumber}&page_limit=${pageLimit}&${searchType}=${searchField}`,
  CMI_CERTIFICATE_SAVE_REMARK: `saveCertificateRemark`,
  CMI_CERTIFICATE_UPDATE_REMARK: `updateCertificateRemark`,
  CMI_CERTIFICATE_UPLOAD: `uploadCMICertificate`,
  CREATE_PUSH_NOTIFICATION: `createPushNotificationMessages`,
  CREDITS_QUALIFICATION_LEVEL: `get_master_table_data?table=qualification_level`,
  CREATE_REDEMPTION_DETAILS: `createOrUpdateRedemptionDetails`,
  CREATE_REDEMPTION_RATE: `createOrUpdateRedemptionRate`,
  CREATE_REWARD_TYPE_POINT_DETAILS: `addRewardTypePointDetailsData`,
  CURRENT_REWARD_TYPE_POINT_DETAILS: (rewardTypePointDetailsId: number) => `getCurrentRewardTypePoint?id=${rewardTypePointDetailsId}`,
  CURRENCIES: `currencies`,

  DIFFICULTY_LEVELS_GET: `get_master_table_data?table=question_difficulty_level`,
  DISCUSSION_COMMENTS_GET: (pageNumber: number, pageLimit: number) =>
   `getallComments?page=${pageNumber}&page_limit=${pageLimit}`,
  DISCUSSION_COMMENTS_GET_SEARCH: (pageNumber: number, pageLimit: number, searchText: string) =>
   `getallComments?page=${pageNumber}&page_limit=${pageLimit}&keyword=${searchText}`,
  DISCUSSION_COMMENT_REPLY_GET: (postId: number, pageNumber: number, pageLimit: number) =>
  `discussion/reply/${postId}?page=${pageNumber}&page_limit=${pageLimit}`,
  DISCUSSION_MODERATOR_SAVE: `moderation`,
  DISCUSSION_ADMIN_REPLY: `adminReply`,
  DOCUMENT_PROOF_TYPES: 'upload-document/types',
  DOCUMENT_VERIFICATION: `/admin/document/verification`,

  EMAIL_EXIST: (email: string) => `checkMailAlreadyExists`,
  EVENT_CATERGORIES_GET : `getEventCategories`,
  EVENTS_GET : `getEvents`,
  EVENT_CREATE: `saveEvents`,
  EVENT_DELETE: (eventId: string) => `deleteEvent/${eventId}`,
  EVENT_UPDATE: (eventId: string) => `updateEvent/${eventId}`,
  EXEMPTION_MODULE_DETAILS: (externalUID: number, externalCID: number) => `api/ModuleExemption/GetModule/${externalUID}/${externalCID}`,

  FAQ_GET: `getAllGeneralFAQ`,
  FAQ_SAVE: `saveFAQ`,
  FAQ_UPDATE: (updateFaqById) => `updateFAQ/${updateFaqById}`,
  FAQ_DELETE_BY_ID: (deleteFaqById: number) => `deleteFAQ/${deleteFaqById}`,
  FAQ_CATEGORIES_GET: `getFAQCategories`,
  FEEDBACK_ALL_FORMS_GET: (pageNumber: number, pageLimit: number) => 
  `feedback/getAllForms?page=${pageNumber}&page_limit=${pageLimit}`,
  FEEDBACK_CREATE_FORM_SAVE: `admin/feedback/form`,
  FEEDBACK_FORM_DELETE: (formId: number) => `admin/feedback/form/${formId}`,
  FEEDBACK_FORM_UPDATE: (formId: number) => `admin/feedback/form/${formId}`,
  FEEDBACK_INPUT_TYPES_GET: `admin/feedback/form/getAnswerInputTypes`,
  FEEDBACK_REMOVE_OPTION: (optionId: number) => `admin/feedback/form/options/${optionId}`,
  FEEDBACK_REMOVE_QUESTION: (questionId: number) => `admin/feedback/form/question/${questionId}`,
  FEEDBACK_TEMPLATE_TYPES_GET: `admin/feedback/form/getTemplates`,
  FEEDBACK_VIEW_FORM_GET: (formId: number) => `admin/feedback/form/${formId}`,
  FEEDBACK_UPDATE_TOGGLE_STATUS: (formId: number) => `admin/feedback/form/${formId}/toggle-active`,

  GENERATE_SAMPLE_CERTIFICATE_SAVE: (courseId: string) => `generateSampleCertificate/${courseId}`,

  HARD_COPY_GET: (type: number, label: string, value: string, pageLimit: number, page: number) => 
    `getHardCopyDetails?page=${page}&page_limit=${pageLimit}&type=${type}&${label}=${value}`,
  HARDCOPY_PROOF_UPDATE: `updateProof`,
  
  INVOICE_COMPLETED_COURSES_GET:(pageNumber: number, pageLimit: number) =>
   `getCourseCompletedList?page=${pageNumber}&page_limit=${pageLimit}`,
  INVOICE_COMPLETED_COURSES_GET_WITH_SEARCH: (pageNumber: number, pageLimit: number, searchType: string, searchField: string) =>
  `getCourseCompletedList?page=${pageNumber}&page_limit=${pageLimit}&${searchType}=${searchField}`,
  INVOICE_GENERATED_LIST_GET: (pageNumber: number, pageLimit: number) => 
  `getInvoiceList?page=${pageNumber}&page_limit=${pageLimit}`,
  INVOICE_GENERATED_LIST_GET_WITH_SEARCH: (pageNumber: number, pageLimit: number,  searchType: string, searchField: string) => 
  `getInvoiceList?page=${pageNumber}&page_limit=${pageLimit}&${searchType}=${searchField}`,
  INVOICE_CREATE_SAVE: `createOrUpdateInvoice`,
  INVOICE_SEND_EMAIL: (invoiceId: string) => `sendInvoiceMail/${invoiceId}`,
  INVOICE_UPDATE: (invoiceId: string) => `createOrUpdateInvoice/${invoiceId}`,

  OFFERS_ADD_OR_UPDATE_OFFER: `offers/add-offers`,
  OFFERS_COUNTRIES: `countries`,
  OFFERS_COURSE_CATEGORY: `getCertTypeByQualId/1`,
  OFFERS_COURSE_LIST: `courses?type=short_courses`,
  OFFERS_GET_OFFER_TYPES: `offers/get-offers-types`,
  OFFERS_PARENT_LIST: `parentmodules`,
  OFFERS_PREVIEW_LIST: (pageNumber: number, pageLimit: number, status: number) =>
  `offers/get-offers-details?page=${pageNumber}&page_limit=${pageLimit}&status=${status}`,
  OFFERS_REMOVE: (offerId: number) => `offers/disable-offers/${offerId}`,

  PARENT_APPLY_TO_ALL: (moduleId: number) => `applyToAll/${moduleId}`,
  PARENT_APPLY_TO_FUTURE_ENROLLMENT: (moduleId: number) => `applyFutureEnrollment/${moduleId}`,
  PAYMENT_DECLINE_MAIL: (orderId: number) => `paymentDeclinedMail/${orderId}`,
  PAYMENT_FOLLOW_UP_DELETE_REMARK: (followUpId: string, remarkId: string) => 
  `payment-followup/${followUpId}/remark/${remarkId}`,
  PAYMENT_FOLLOW_UP_EDIT_REMARK: (followUpId: string) => `payment-followup/${followUpId}/remark`,
  PAYMENT_FOLLOW_UP_EXECUTIVE_GET: `get_master_table_data?table=master_executive`,
  PAYMENT_FOLLOW_UP_GET_WITH_SEARCH: (pageNo: number, pageSize: number, searchType: string, searchField: string, status: number = null, executive: string = null) => 
  `payment-followup?page=${pageNo}&page_limit=${pageSize}&status=${status}&executive=${executive}&${searchType}=${searchField}`,
  PAYMENT_FOLLOW_UP_SAVE_REMARK: (followUpId: string) => `payment-followup/${followUpId}/remark`,
  PAYMENT_FOLLOW_UP_SEND_MAIL: (followUpId: string) => `payment-followup/${followUpId}/mail`,
  PAYMENT_FOLLOW_UP_STATUS_GET: `get_master_table_data?table=master_payment_followup_status`,
  PAYMENT_FOLLOW_UP_UPDATE_STATUS: (followUpId: string) => `payment-followup/${followUpId}`,
  PAYMENT_MODE: `getPaymentMode`,
  PAYMENT_PLAN_FASTRACK: (externalCourseId: number) => `api/PaymentPlan/GetSchedulePaymentPlan/${externalCourseId}`,
  PAYMENT_PLAN_FEE_DETAILS: (planId: number) => `api/PaymentPlan/GetPaymentPlanFeeDetails/${planId}`,
  PAYMENT_PLAN_FLEXIBLE: (externalCourseId: number) => `api/PaymentPlan/GetModulePaymentPlan/${externalCourseId}`,
  PAYMENT_PLAN_GET_BATCH: `Course/GetBatch`,
  PAYMENT_SETTLEMENT_GET: (pageNo: string, pageSize: string) =>
    `getPaymentSettlements?page=${pageNo}&page_limit=${pageSize}`,
  PAYMENT_SETTLEMENT_GET_WITH_ORDER_ID: (pageNo: string, orderId: string, pageSize: string) =>
    `getPaymentSettlements?page=${pageNo}&orderId=${orderId}&page_limit=${pageSize}`,
  PAYMENT_SETTLEMENT_GET_WITH_EMAIL: (pageNo: string, email: string, pageSize: string) =>
    `getStudenEligibleForCertficate?email=${email}&page=${pageNo}&page_limit=${pageSize}`,
  PAYMENT_SETTLEMENT_GET_WITH_STUDENT_NAME: (pageNo: string, name: string, pageSize: string) =>
    `getPaymentSettlements?page=${pageNo}&name=${name}&page_limit=${pageSize}`,
  PAYMENT_SETTLED_GET: (pageNo: number, pageSize: number) =>
    `getPaymentSettlements?settled=true&page=${pageNo}&page_limit=${pageSize}`,
  PAYMENT_SETTLED_GET_WITH_ORDER_ID: (pageNo: number, orderId: string, pageSize: number) =>
    `getPaymentSettlements?settled=true&page=${pageNo}&page_limit=${pageSize}&orderId=${orderId}`,
  PAYMENT_SETTLED_GET_WITH_EMAILID: (pageNo: number, emailId: string, pageSize: number) =>
    `getPaymentSettlements?settled=true&page=${pageNo}&page_limit=${pageSize}&email=${emailId}`,
  PAYMENT_SETTLEMENT_SAVE_WITH_ORDER_ID: (orderId: string) => `settlement/${orderId}`,
  PAYMENT_SETTLEMENT_SAVE_WITH_CID_UID: `postSettlementByUidCid`,
  PROFILES_LIST: (pageNo: number, pageSize: number) => `profile/pending-profiles?page=${pageNo}&page_limit=${pageSize}`,
  PROFILES_SAVE: (uid: number) => `profile/save-profile?uid=${uid}`,
  PROFILES_VERIFY: (id: number, uid: number, createdAt: string) => `profile/verify-profile?id=${id}&uid=${uid}&created_at=${createdAt}`,

  POLLS_CREATE_OR_UPDATE: `admin/polls/adminCreate`,
  POLLS_DELETE: (id: number) => `poll-questions/${id}`,
  POLLS_LIST: (sortOrder: string, pageNumber: number, pollStatus: string, searchText: any) => 
  `getAllPollQuestions?sort=${sortOrder}&status=${pollStatus}&page=${pageNumber}&search=${searchText}`,
  POLLS_TOGGLE_STATUS: (id: number) => `poll-questions/${id}/status`,

  PUSH_NOTIFICATION_CATEGORIES_ALL: `getPushNotificationCategories`,
  PUSH_NOTIFICATION_CATEGORIES_LIMIT: (pageNumber: number, pageLimit: number) =>
  `getPushNotificationCategories?page=${pageNumber}&per_page=${pageLimit}`,
  PUSH_NOTIFICATION_LIST: (optionId:number, value: string, pageLimit: number, page: number, order: string) =>
  `getPushNotificationMessages?page=${page}&pageLimit=${pageLimit}&searchCat=${optionId}&searchText=${value}&pageOrder=${order}`,
  PUSH_NOTIFICATION_SCHEDULE: `getPushNotificationScheduleConfig`,
  PUSH_NOTIFICATION_SCHEDULE_FREQUENCY_CATEGORIES: `getPushNotificationScheduleCron`,

  RECEIPT_CREATE_LIST: (pageNumber: number, pageLimit: number) => 
    `getClaimedCertificateDetailsList?page=${pageNumber}&page_limit=${pageLimit}`,
  RECEIPT_CREATE_LIST_WITH_SEARCH: (pageNumber: number, pageLimit: number, searchType: string, searchField: string) =>
    `getClaimedCertificateDetailsList?page=${pageNumber}&page_limit=${pageLimit}&${searchType}=${searchField}`,
  RECEIPT_CREATE_SAVE: `createReceipt`,
  RECEIPT_GENERATED_LIST: (pageNumber: number, pageLimit: number) => 
    `getReceiptList?page=${pageNumber}&page_limit=${pageLimit}`,
  RECEIPT_GENERATED_LIST_WITH_SEARCH: (pageNumber: number, pageLimit: number, searchType: string, searchField: string) =>
    `getReceiptList?page=${pageNumber}&page_limit=${pageLimit}&${searchType}=${searchField}`,
  RECEIPT_SEND_MAIL: (receiptId: string) => `sendReceiptMail/${receiptId}`,
  
  QUOTES_LIST: (value: string, pageLimit: number, page: number, order: string) =>
  `getLoaderQuotes?page=${page}&page_limit=${pageLimit}&keyword=${value}&order=${order}`,
  QUOTES_CREATE: `createLoaderQuote`,
  QUOTES_UPDATE: (id: string) => `updateLoaderQuotes/${id}`,
  QUOTES_DELETE: (id: string) => `deleteLoaderQuotes/${id}`,

  REDEMPTION_DETAILS: `getRedemptionDetails`,
  REDEMPTION_DETAILS_CREATE: `createOrUpdateRedemptionDetails`,
  REDEMPTION_DETAILS_UPDATE: (updateRedemptionOfferId: number) => `createOrUpdateRedemptionDetails?id=${updateRedemptionOfferId}`,
  REDEMPTION_OFFER_REMOVE: (redemptionId: number) => `removeRedemptionOffer?id=${redemptionId}`,
  REDEMPTION_RATE: `getRedemptionRate`,
  REDEMPTION_RATE_CREATE: `createOrUpdateRedemptionRate`,
  REDEMPTION_RATE_DETAILS: `getRedemptionRateDetails`,
  REDEMPTION_RATE_REMOVE: (redemptionId: number) => `removeRedemptionRate?id=${redemptionId}`,
  REDEMPTION_RATE_UPDATE: (updateRedemptionRateId: number) => `createOrUpdateRedemptionRate?id=${updateRedemptionRateId}`,
  REDEMPTION_TYPES: `getRedemptionTypes`,

  REWARD_TYPE_POINT_DETAILS: `getRewardTypePointDetailsData`,
  REWARD_TYPE_POINT_DETAILS_CREATE: `addRewardTypePointDetailsData`,
  REWARD_TYPE_POINT_DETAILS_CURRENT: (rewardTypePointDetailsId: number) => `getCurrentRewardTypePoint?id=${rewardTypePointDetailsId}`,
  REWARD_TYPE_POINT_DETAILS_UPDATE: (rewardTypePointDetailsId: number) => `addRewardTypePointDetailsData?id=${rewardTypePointDetailsId}`,
  REWARD_TYPE_POINT_DETAILS_REMOVE: (rewardTypePointDetailsId: number) => `removeRewardTypePointDetailsData?id=${rewardTypePointDetailsId}`,
  REWARD_TYPES_ACTIVE: `getActiveRewardTypes`,

  SHORT_COURSES_LIST_GET: `getShortCourses`,
  STATES_BY_COUNTRY: (countryId: number) => `statesByCountry/${countryId}`,
  STUDY_MODE_TYPES: `get_master_table_data?table=study_mode`,

  TESTIMONAL_DELETE: (testimonialId: number) => `deleteTestimonial/${testimonialId}`,
  TESTIMONAL_GET: `admin/testimonials/get`,
  TESTIMONAL_SAVE: `createTestimonial`,
  TESTIMONIAL_UPDATE: (updateTestimonialId: number) => `updateTestimonial/${updateTestimonialId}`,
  TOGGLE_PUSH_NOTIFICATION_CATEGORY_STATUS: (id: number) => `togglePushNotificationCategoryStatus/${id}`,
  TOGGLE_PUSH_NOTIFICATION_STATUS: (id: number) => `togglePushNotificationMessages/${id}`,
  TRACKING_STATUS_UPDATE: `updateTrackingStatus`,

  UPDATE_PUSH_NOTIFICATION: (id: number) => `updatePushNotificationMessages/${id}`,
  UPDATE_PUSH_NOTIFICATION_SCHEDULE: (id: number) => `updatePushNotificationScheduleConfig/${id}`,
  USER_DETAILS_RESTRICTION: `restrict-field`,
  USER_DETAILS_UPDATE: (uid: string) => `updateuserdetails/${uid}`,
  USER_PERMISSIONS_MODULE_GET: `usermodules`,
  USER_PERMISSIONS_GET: `permission`,
  USER_REVIEW_GET: `getAllUserReviews`,
  USER_REVIEW_MODERATOR: (reviewId: number) => `moderateReview/${reviewId}`,
  UTM_DETAILS_SAVE: `addUTMParameters`,

  VALIDATION_COURSE_SLUG: 'validateCourseSlug',
};

export const ExcludingLoaderForEndpoints = [];